import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Components/Common/Style';
import ImageCompare from './Components/Common/ImageCompare';

document.addEventListener('DOMContentLoaded', () => {
	const imgCompareEls = document.querySelectorAll('.wp-block-icb-image-compare');
	imgCompareEls.forEach(imgCompareEl => {
		const attributes = JSON.parse(imgCompareEl.dataset.attributes);
		const { cId, isLabel, beforeLabel, afterLabel, isCap, cap } = attributes;

		const beforeLabelEl = isLabel && beforeLabel && <div className='overlay beforeLabel' dangerouslySetInnerHTML={{ __html: beforeLabel }} />
		const afterLabelEl = isLabel && afterLabel && <div className='overlay afterLabel' dangerouslySetInnerHTML={{ __html: afterLabel }} />
		const captionEl = isCap && cap && <div className='caption' dangerouslySetInnerHTML={{ __html: cap }} />

		createRoot(imgCompareEl).render(<>
			<Style attributes={attributes} clientId={cId} />

			<ImageCompare attributes={attributes} beforeLabelEl={beforeLabelEl} afterLabelEl={afterLabelEl} captionEl={captionEl} />
		</>);

		imgCompareEl?.removeAttribute('data-attributes');
	});
});